import React from "react";

import "./index.css";

const IndexPage = () => (
  <div className="container">
    <div id="shape"></div>
  </div>
)

export default IndexPage
